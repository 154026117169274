<template>
  <v-textarea
    v-model="fieldValue"
    :rows="rows"
    class="base-textarea textarea-field"
    @focus.stop="onFocus"
    @focusout="onBlur"
    :maxlength="counterValue"
    auto-grow
    :row-height="30"
    v-bind="$attrs"
  >
    <template v-slot:label>
      <div class="label">
        {{ label }}
        <span v-if="isFocused && counter" class="counter">
          <span :class="{ danger: value.length >= counterValue }">
            {{ value.length }}
          </span>
          ({{ counterValue }})
        </span>
      </div>
    </template>
  </v-textarea>
</template>

<script>
export default {
  name: "BaseTextarea",
  props: {
    value: {
      type: [String, Number],
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    counter: {
      type: [String, Number, Boolean],
      default: false
    },
    rows: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      isFocused: false
    };
  },
  computed: {
    fieldValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    counterValue() {
      if (Number.isNaN(this.counter)) return undefined;
      return parseInt(this.counter);
    }
  },
  methods: {
    onFocus() {
      this.isFocused = true;
    },
    onBlur() {
      this.isFocused = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/sass/variables";

.base-textarea {
  position: relative;
  ::v-deep(.v-label) {
    width: 100%;
    max-width: 100%;
    transform-origin: top left;
    .label {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .danger {
        color: #cf1019;
      }
    }
    &.v-label--active {
      transform: translateY(-18px) scale(0.75);
      width: 133%;
      max-width: 133%;
    }
  }
  ::v-deep(.v-input__control) {
    textarea {
      caret-color: #cf1019 !important;
      padding: 5px 0 !important;
    }
  }
}
</style>
