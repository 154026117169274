import { debounce } from "lodash";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  data: () => ({
    // geocodingTimerId: null,
    geocodingSearchModel: "",
    geocodingIsLoading: false,
    geocodingLineStyle: {
      "font-size": "12px",
    },
    // geocodingSelectedAddress: {},
  }),
  computed: {
    ...mapGetters(["geocodingResults"]),
  },
  methods: {
    ...mapActions(["getAddress"]),
    ...mapMutations(["setResultsList"]),
    // geocodingGetAddress(query) {
    //   console.log("--- Geocoding: get address ---", query);
    //   clearInterval(this.geocodingTimerId);
    //   this.geocodingIsLoading = true;
    //   this.geocodingTimerId = setTimeout(async () => {
    //     console.log("--- Geocoding: timer ID ---", this.geocodingTimerId);
    //     await this.getAddress(query);
    //     this.geocodingIsLoading = false;
    //   }, 600);
    // },
    geocodingGetAddress: debounce(async function (query) {
      // console.log("--- Geocoding: get address ---", query);
      this.geocodingIsLoading = true;
      await this.getAddress(query);
      this.geocodingIsLoading = false;
    }, 300),
    // geocodingOnAddressChange(address) {
    //   this.geocodingSelectedAddress = address;
    // },
  },
  watch: {
    geocodingSearchModel: {
      handler(val) {
        if (val && val.length > 3) return this.geocodingGetAddress(val);
        if (this.geocodingIsLoading) return;

        this.setResultsList([]);
      },
    },
  },
};
