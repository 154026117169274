<template>
  <v-combobox
    v-bind="$attrs"
    :key="count"
    v-model="model"
    :search-input.sync="geocodingSearchModel"
    :items="geocodingResults"
    item-text="formatted"
    :loading="geocodingIsLoading"
    no-filter
    append-icon=""
  >
    <template v-slot:item="{ item }">
      <div :style="geocodingLineStyle">{{ item.formatted }}</div>
    </template>
  </v-combobox>
</template>

<script>
import geocoding from "@/common/mixins";
export default {
  name: "BaseAddressSearch",
  inheritAttrs: false,
  props: {
    value: [String, Number],
    geocodeType: {
      type: String,
      default: "street",
      validator: (val) => ["street", "city"].includes(val),
    },
  },
  data: () => ({
    // items: [1, 2, 3],
    count: 0,
  }),
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        // console.log("VALUE", value);
        this.$emit("on-place-change", value);
        this.count++;
        if (!value) return this.$emit("input", "");
        if (value && !value.formatted) return this.$emit("input", value);

        // set formatted value
        let formatted = this.getAddressString(value);
        this.$emit("input", formatted);
      },
    },
  },
  mixins: [geocoding],
  methods: {
    getAddressString(place) {
      if (!place) return;
      let formattedAddress = [];
      let { house_number, road, town, city, state_code, country_code } = place;

      if (this.geocodeType === "city") {
        if (town) formattedAddress.push(town);
        if (city) formattedAddress.push(city);
        if (state_code) formattedAddress.push(state_code);
        if (country_code) formattedAddress.push(country_code.toUpperCase());
        return formattedAddress.join(", ");
      }
      // default value
      if (!house_number && !road) return place.formatted;

      if (house_number) formattedAddress.push(house_number);
      if (road) formattedAddress.push(road);
      return formattedAddress.join(" ");
    },

    // Google places methods
    initGoogleApi() {
      if (!google) throw new Error("missing google map core");
      try {
        this.autocomplete = new google.maps.places.Autocomplete(
          this.$refs?.autocomplete?.$refs.input,
          { types: [this.geocodeType] }
        );
        this.autocomplete.addListener(
          "place_changed",
          this.onGooglePlaceChanged
        );

        this.autocomplete.setFields([
          "address_components",
          "formatted_address",
          "geometry",
        ]);

        setTimeout(() => {
          this.$refs.autocomplete.$refs.input.placeholder = "";
        }, 0);
      } catch (e) {
        throw Error(e);
      }
    },
    onGooglePlaceChanged() {
      const place = this.autocomplete.getPlace();
      if (place) {
        this.model.data.address = this.prepareAddress(place);
        this.model.input = this.model.data.address.formattedAddress;

        const { geometry } = place;
        if (geometry && geometry.location) {
          this.model.data.coords = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };
        }
      }
    },
    prepareAddress(place) {
      const addressParts = place.address_components || [];
      const keyMap = {
        locality: "city",
        administrative_area_level_1: "state",
        country: "country",
        postal_code: "zip",
        street_number: "street_number",
        route: "route",
      };
      const res = {};
      for (const item of addressParts) {
        const [type] = item.types;
        res[keyMap[type]] = item;
      }
      if (res?.route?.short_name && this.geocodeType !== "(cities)")
        res.formattedAddress += `, ${res?.route?.short_name} `;
      if (res?.city?.short_name)
        res.formattedAddress = `${res?.city?.short_name}`;
      if (res?.state?.short_name)
        res.formattedAddress += `, ${res?.state?.short_name}`;
      if (res?.country?.short_name)
        res.formattedAddress += `, ${res?.country?.short_name}`;

      return res;
    },
  },
  mounted() {
    // this.initGoogleApi();
  },
};
</script>

<style lang="scss" scoped></style>
